<template>
  <div class="list-container">
    <BreadCrumbLink
      v-if="useRoute().params.tab==='guide'"
            tab-key=""
            tab-name="Wiki"
            :self-close="true"
            @close="useRouter().go(-1)"
          />
    <div class="spacer" />
    <div v-for="(item, index) in breadCrumbsList" :key="index">
      <SecondNode :bread-crumb-parent="item" />
      <div class="spacer" />
    </div>
    <div>
      <infinite-loading
        v-if="!isFinish"
        class="infinite-loading"
        @infinite="loadMore"
      ></infinite-loading>
      <div v-else class="infinite-loading-finish"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { $isDevEnv } = useNuxtApp();
import type { Breadcrumb } from '@/components/pb/entity/xgamer.ts';
import BreadCrumbLink from '../common/bread-crumb-link.vue';
import SecondNode from './second-node.vue';
import { ref } from 'vue';
import type { GetBreadcrumbsReply } from '../../pb/games/games_request.ts';
import InfiniteLoading from 'v3-infinite-loading';
import 'v3-infinite-loading/lib/style.css';
import NetworkMgr from '~/util/network-mgr.ts';
let breadCrumbsList: BreadCrumb[] = ref([]);
let isFinish = ref(false);
let begin = ref('');
let loading = ref(false);
const props = defineProps({
  propGuide: {
    type: Array,
    required: false,
    default: () => []
  },
  propNext: {
    type: String,
    required: false,
    default: ''
  },
  propIsFinish: {
    type: Boolean,
    required: false,
    default: false
  }
});

begin.value = props.propNext;
breadCrumbsList.value = props.propGuide;
isFinish.value = props.propIsFinish;

const loadMore = async () => {
  if (loading.value || isFinish.value) {
    console.log('load guid list , state is loading , return');
    return;
  }
  loading.value = true;
  const response = await NetworkMgr.getInstance().fetch(
    'games/games/get_breadcrumbs',
    {
      game_id: useSelectedGameDetail().value.id,
      begin:begin.value,
      site_id: 'SITE_BLACK_MYTH',
    }
  );

  if (response) {
    const getBreadCrumbsReply = response as GetBreadcrumbsReply;
    breadCrumbsList.value.push(
      ...(getBreadCrumbsReply.breadcrumbs as Breadcrumb[])
    );
    isFinish.value = getBreadCrumbsReply.isFinish == 1;
    begin.value = getBreadCrumbsReply.next;
  }
  loading.value = false;
};

const getSeoTitle = () => {
  return `${useSelectedGameDetail().value.name} Guide & Wiki`;
};

const getSeoDescription = () => {
  const gameName = useSelectedGameDetail().value.name;
  const breadCrumbs = breadCrumbsList.value.map((item) => item.name);
  const breadCrumbsText
    = breadCrumbs.length > 0 ? `, ${breadCrumbs.join(', ')}` : '';
  return `Master ${gameName} with our extensive guides and wikis. Discover ${breadCrumbsText}. Explore the game with confidence, armed with the best guides and wikis available!`;
};

useSeoMeta({
  title: $isDevEnv() ? '' : getSeoTitle(),
  description: $isDevEnv() ? '' : getSeoDescription(),
  ogTitle: getSeoTitle(),
  ogDescription: getSeoDescription(),
  twitterTitle: getSeoTitle(),
  twitterDescription: getSeoDescription()
});
</script>

<style scoped>
.infinite-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.infinite-loading-finish {
  display: none;
}

.spacer {
  height: 16px;
  background-color: transparent;
}

.list-container {
  background-color: #f6f8f7;
  padding: 20px 40px 0px 40px;
}

@media screen and (max-width: 999px) {
  .spacer {
    height: 8px;
    background-color: transparent;
  }
  .list-container {
    background-color: #f6f8f7;
    padding: 20px 10px 0px 10px;
    width: calc(100vw - 20px);
  }
}
</style>
