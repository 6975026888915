<template>
  <div class="root">
    <div class="grid-container">
      <div v-for="item in breadCrumbsList" :key="item.id">
        <NuxtLink
          :class="{ 'grid-item-content-zombie': item.name == '' }"
          :to="jumpDetail(item)"
          class="grid-item-content"
        >
          <div class="grid-item-text">
            {{ item.name }}
          </div>
          <div v-if="item.isHot">
            <div class="hot-tag">HOT</div>
          </div>
          <div v-else-if="item.isNew">
            <div class="new-tag">New</div>
          </div>
        </NuxtLink>
      </div>
    </div>
    <div v-if="!isFinish" class="loadMore-button-container">
      <div class="spacer" />
      <div class="loadMore-button" @click="loadMore">
        <span>{{ $t('seeMore') }}</span>
        <NuxtImg
          :src="ImageUtil.getLocalImgFromCdn('ic_arrow_down_blue')"
          alt="see more guides"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ImageUtil from '@/util/image-util.ts';
import '~/assets/css/home/guide/wiki-gridview.css';
import { ref, watch } from 'vue';
import GameUtil from '@/util/game-util.ts';
import type { GetBreadcrumbsReply } from '@/components/pb/games/games_request.ts';
import type { Breadcrumb } from '@/components/pb/entity/xgamer.ts';
import { useRoute } from 'vue-router';
import NetworkMgr from '~/util/network-mgr.ts';
const isMobileRef = computed(() => {
  return useIsMobileState().value;
});
const route = useRoute();
let breadCrumbsList: Breadcrumb[] = ref([]);
let isFinish = ref(false);
let begin = ref('');
let loading = ref(false);

const props = defineProps({
  breadCrumbParent: {
    type: Object,
    required: true
  }
});

const jumpDetail = (item) => {
  if (item === undefined || item.name == '') return;
  let gameName = route.params.game;
  if (gameName == undefined) {
    gameName = GameUtil.defaultGameDetail.key;
  }
  return `/wiki/${item.id}`;
};

const loadMore = async () => {
  if (loading.value || isFinish.value) return;
  loading.value = true;
  const response = await NetworkMgr.getInstance().fetch(
    'games/games/get_breadcrumbs',
    {
      game_id: useSelectedGameDetail().value.id,
      begin: begin.value,
      count: 8,
      parent_id: props.breadCrumbParent.id,
      site_id: 'SITE_BLACK_MYTH',
    }
  );
  if (response) {
    const getBreadCrumbsReply = response as GetBreadcrumbsReply;
    breadCrumbsList.value.push(
      ...(getBreadCrumbsReply.breadcrumbs as Breadcrumb[])
    );
    isFinish.value = getBreadCrumbsReply.isFinish == 1;
    begin.value = getBreadCrumbsReply.next;
    if (breadCrumbsList.value.length < 3) {
      breadCrumbsList.value.push({ name: '' });
    }
  }
  loading.value = false;
};

const { data } = await NetworkMgr.getInstance().request(
  `get_breadcrumbs_third_${props.breadCrumbParent.id}`,
  'games/games/get_breadcrumbs',
  {
    begin: begin.value,
    count:isMobileRef.value?4:8,
    parent_id: props.breadCrumbParent.id,
    game_id: useSelectedGameDetail().value.id,
    site_id: 'SITE_BLACK_MYTH',
  },
  { lazy: false }
);

if (data) {
  const getBreadCrumbsReply = data as GetBreadcrumbsReply;
  breadCrumbsList.value.push(
    ...(getBreadCrumbsReply.value.breadcrumbs as Breadcrumb[])
  );
  isFinish.value = getBreadCrumbsReply.value.isFinish == 1;
  begin.value = getBreadCrumbsReply.value.next;
  //凑一个三个的数组，用于展示grid
  if (breadCrumbsList.value.length < 3) {
    breadCrumbsList.value.push({ name: '' });
  }
}

watch(
  () => props.breadCrumbParent,
  () => {
    isFinish.value = false;
    begin.value = '';
    breadCrumbsList.value = [];
    loadMore();
  },
  { deep: true }
);
</script>

<style scoped>
.root {
  overflow: hidden;
}

.grid-item-content-zombie {
  background-color: #f6f8f7;
}

@media screen and (max-width: 999px) {
  .spacer {
    height: 16px;
    background-color: transparent;
  }

  .grid-item-content-zombie {
    display: none;
  }
}

@media screen and (min-width: 1000px) {
  .spacer {
    height: 16px;
    background-color: transparent;
  }
}
</style>
