<template>
  <div v-if="isParentBreadcrumbHasChildren" class="first-node-title">
    <span>{{ props.breadCrumbParent.name }}</span>
    <div class="spacer" />
  </div>
  <div v-if="showTabBar" class="tab-container">
    <div class="custom-tabBar">
      <div
        v-for="tab in breadCrumbsList"
        :key="tab.id"
        :class="{ 'tab-active': activeTab.id === tab.id }"
        class="tab-item"
        @click="() => changeTab(tab)"
      >
        <span>{{ tab.name == '' ? tab.id : tab.name }}</span>
        <div v-if="activeTab.id === tab.id" class="tab-underline"></div>
      </div>
    </div>
    <div class="spacer" />
    <div class="tab-root-container">
      <ThirdNode
        v-if="activeTab !== undefined"
        :bread-crumb-parent="breadCrumbCurrent"
      />
    </div>
  </div>
  <div v-else>
    <div class="grid-container">
      <div v-for="item in breadCrumbsList" :key="item.id" class="grid-item">
        <NuxtLink
          :class="{ 'grid-item-content-zombie': item.name == '' }"
          :to="jumpDetail(item)"
          class="grid-item-content"
        >
        <div v-if="item.brief.coverImage">
          <NuxtImg :src="ImageUtil.getWebpUrl(item.brief.coverImage)" format="webp" :alt="item.brief.title" class="grid-item-image"></NuxtImg>
        </div>
          <span class="grid-item-text" style="display: flex;">
            {{ item.name }}
          </span>
        </NuxtLink>
      </div>
    </div>
    <div v-if="!isFinish" class="loadMore-button-container">
      <div class="spacer" />
      <div class="loadMore-button" @click="loadMore">
        <span>{{ $t('seeMore') }}</span>
        <NuxtImg
          :src="ImageUtil.getLocalImgFromCdn('ic_arrow_down_blue')"
          alt="see more guides"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ImageUtil from '@/util/image-util.ts';
import { ref } from 'vue';
import GameUtil from '@/util/game-util.ts';
import type { GetBreadcrumbsReply } from '../../pb/games/games_request.ts';
import type { Breadcrumb } from '../../pb/entity/xgamer.ts';
import { BreadcrumbType } from '../../pb/entity/xgamer.ts';
import ThirdNode from './third-node.vue';
import '~/assets/css/home/guide/wiki-gridview.css';
import { useRoute } from 'vue-router';
import NetworkMgr from '~/util/network-mgr.ts';
const route = useRoute();
let breadCrumbsList: Breadcrumb[] = ref([]);
let isFinish = ref(false);
let begin = ref('');
let loading = ref(false);
let activeTab = reactive({ name: '', id: 0 });
const isMobileRef = computed(() => {
  return useIsMobileState().value;
});
const props = defineProps({
  breadCrumbParent: {
    type: Object,
    required: true
  }
});

const getBreadCrumb = (tab): Breadcrumb => {
  let breadCrumb = breadCrumbsList.value.find((item) => item.id == tab.id);
  if (breadCrumb == undefined) {
    breadCrumb = breadCrumbsList.value[0];
  }
  return breadCrumb;
};

const changeTab = (tab) => {
  activeTab.id = tab.id;
  if (tab.name == '') {
    activeTab.name = tab.id;
    return;
  }
  activeTab.name = tab.name;
};

const jumpDetail = (item) => {
  if (item === undefined || item.name == '') return;
  let gameName = route.params.game;
  if (gameName == undefined) {
    gameName = GameUtil.defaultGameDetail.key;
  }
  return `/wiki/${item.id}`;
};

const showTabBar = computed(() => {
  let showTabBar
    = breadCrumbsList.value.length > 0 && isCurrentNodeHasChildren();
  if (showTabBar && activeTab.value == undefined) {
    if (breadCrumbsList.value[0].name != '') {
      activeTab.name = breadCrumbsList.value[0].name;
    } else {
      activeTab.value = breadCrumbsList.value[0].id;
    }
    activeTab.id = breadCrumbsList.value[0].id;
  }
  return showTabBar;
});

//判断props.breadCrumbParent是否包含子节点，如果没有，那么整体隐藏
const isParentBreadcrumbHasChildren = computed(() => {
  return breadCrumbsList.value.length > 0;
});

//判断当前node是否还有子节点，如果没有，那么就是叶子节点，直接展示；如果有，那么需要继续查询third node
const isCurrentNodeHasChildren = (): boolean => {
  if (breadCrumbsList.value.length == 0) {
    return false;
  }
  let first = breadCrumbsList.value[0];
  let isDir = first.type == BreadcrumbType.BREADCRUMB_TYPE_SUB_CATEGORY;
  return isDir;
};

let breadCrumbCurrent = computed(() => getBreadCrumb(activeTab));

const loadMore = async () => {
  if (loading.value || isFinish.value) return;
  loading.value = true;
  const response = await NetworkMgr.getInstance().fetch(
    'games/games/get_breadcrumbs',
    {
      begin: begin.value,
      parent_id: props.breadCrumbParent.id,
      game_id: useSelectedGameDetail().value.id,
      site_id: 'SITE_BLACK_MYTH',
      count:isMobileRef.value?4:8
    }
  );

  if (response) {
    console.log(response)
    const getBreadCrumbsReply = response as GetBreadcrumbsReply;
    breadCrumbsList.value.push(
      ...(getBreadCrumbsReply.breadcrumbs as Breadcrumb[])
    );
    isFinish.value = getBreadCrumbsReply.isFinish == 1;
    begin.value = getBreadCrumbsReply.next;
    //凑一个三个的数组，用于展示grid
    if (breadCrumbsList.value.length < 3) {
      breadCrumbsList.value.push({ name: '' });
    }
  }
  console.log(breadCrumbsList)
  loading.value = false;
};

const { data } = await NetworkMgr.getInstance().request(
  `get_breadcrumbs_second_${props.breadCrumbParent.id}`,
  'games/games/get_breadcrumbs',
  {
    begin: begin.value,
      parent_id: props.breadCrumbParent.id,
      game_id: useSelectedGameDetail().value.id,
      site_id: 'SITE_BLACK_MYTH',
      count:isMobileRef.value?4:8
  },
  { lazy: false }
);

if (data) {
  const getBreadCrumbsReply = data as GetBreadcrumbsReply;
  breadCrumbsList.value.push(
    ...(getBreadCrumbsReply.value.breadcrumbs as Breadcrumb[])
  );
  //test
  isFinish.value = getBreadCrumbsReply.value.isFinish == 1;
  begin.value = getBreadCrumbsReply.value.next;
  //凑一个三个的数组，用于展示grid
  if (breadCrumbsList.value.length < 3) {
    breadCrumbsList.value.push({ name: '' });
  }
}
</script>

<style scoped>
.first-node-title span {
  font-size: 20px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.9);
}

.custom-tabBar {
  background-color: #f6f8f7;
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: thin; /* Firefox */
}

/* 自定义滚动条样式 */
.custom-tabBar::-webkit-scrollbar {
  width: 2px;
  height: 6px;
}

/* 滚动条轨道 */
.custom-tabBar::-webkit-scrollbar-track {
  background: #f6f8f7;
}

/* 滚动条滑块 */
.custom-tabBar::-webkit-scrollbar-thumb {
  background: transparent; /* 默认情况下滚动条是透明的 */
}

/* 滚动条滑块：鼠标悬停 */
.custom-tabBar:hover::-webkit-scrollbar-thumb {
  background: #888; /* 当鼠标悬停在元素上时，滚动条变为可见 */
}

.tab-item {
  padding: 6px 40px 6px 0px;
  cursor: pointer;
  position: relative;
}

.tab-item span {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
}

.tab-item:hover {
  color: rgba(0, 0, 0, 0.9);
}

.tab-active span {
  color: rgba(0, 0, 0, 0.9);
}

.tab-underline {
  position: absolute;
  bottom: 0;
  left: 0px;
  right: 40px;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.7);
}

.tab-root-container {
  overflow: auto;
  max-height: calc(100vh - 88px - 16px - 16px);
}

.tab-container {
  background-color: #f6f8f7;
}

.grid-item-content-zombie {
  background-color: #f6f8f7;
}

@media screen and (max-width: 999px) {
  .spacer {
    height: 16px;
    background-color: transparent;
  }

  .grid-item-content-zombie {
    display: none;
  }

  .tab-item span {
    font-size: 14px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.9);
  }
}

@media screen and (min-width: 1000px) {
  .spacer {
    height: 16px;
    background-color: transparent;
  }
}
</style>
